<template>
  <CRow v-if="platformPermissionsLoaded && ((!formIdExternal && checkPermission('service.forms.add')) || (formIdExternal && checkPermission('service.forms.edit')))" class="service form">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-left">
              <span>{{!formIdExternal ? $t('forms.New_form') : $t('forms.Edit_form')}}</span>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <!-- Form Details -->
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="pb-0">
              <label><b>{{$t('forms.Name_of_form')}}</b></label>
              <CInput maxlength="100" class="mb-0" v-model="form.form_name"/>
            </CCol>
            <CCol cols="6" lg="6" class="pb-0">
              <label><b>{{$t('forms.Category_of_form')}}</b></label>
              <multiselect class="data_table open_absolute"
                           v-model="form.category"
                           :options="formCategories" 
                           :multiple="false"
                           :hide-selected="true"
                           :close-on-select="true"
                           :selectLabel="$t('common.Add_category')"
                           :deselectLabel="$t('common.Remove_category')"
                           track-by="form_category_id_external" 
                           label="label"
                           :disabled="form.form_status_tag === 'published'">
                <span slot="noResult">{{$t('common.no_categories_found')}}</span>
              </multiselect>
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pb-0">
              <label><b>{{$t('forms.Description_of_form')}}</b></label>
              <CTextarea @input="countdown()" rows="2" maxlength="250" class="mb-0" v-model="form.form_description"/>
              <p class="countdown mt-1 mb-0" v-if="remainingCount.form_description > 0">{{remainingCount.form_description}} {{ $t('common.characters_remaining') }}</p>              
            </CCol>
          </CRow>          
          <!-- Form Availability -->
          <CRow class="m-0 datepickers">
            <CCol cols="6" lg="6" class="pb-0">
              <label><b>{{$t('forms.Form_available_from')}}</b></label>
              <b-datetimepicker v-model="formAvailableFrom" :placeholder="$t('common.click_to_select')" editable :datepicker="datepickerOptions">
                <template #left>
                  <CButton color="primary" @click="formAvailableFrom = new Date();"><i class="fas fa-clock mr-1"/>{{$t('Now')}}</CButton>
                </template>
                <template #right>
                  <CButton color="secondary" @click="formAvailableFrom = null;"><i class="fas fa-times mr-1"/>{{$t('Clear')}}</CButton>
                </template>                    
              </b-datetimepicker>
            </CCol>
            <CCol cols="6" lg="6" class="pb-0">
              <label><b>{{$t('forms.Form_available_till')}}</b></label>
              <b-datetimepicker v-model="formAvailableTill" :placeholder="$t('common.click_to_select')" editable :datepicker="datepickerOptions">
                <template #left>
                  <CButton color="primary" @click="formAvailableTill = new Date();"><i class="fas fa-clock mr-1"/>{{$t('Now')}}</CButton>
                </template>
                <template #right>
                  <CButton color="secondary" @click="formAvailableTill = null;"><i class="fas fa-times mr-1"/>{{$t('Clear')}}</CButton>
                </template>                    
              </b-datetimepicker>
            </CCol>            
          </CRow>
          <!-- Form Builder -->
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pb-0">
              <label><b>{{form.form_status_tag !== 'published' ? $t('forms.Form_builder') : $t('forms.Your_form')}}</b></label>
              <div class="form_builder_container position-relative">
                <div v-if="form.form_status_tag === 'published'" class="d-flex align-items-center justify-content-center notice">
                  <span>{{$t('forms.Form_builder_disabled')}}</span>
                </div>              
                <FormBuilder ref="formBuilder" :formData="formData"/>
              </div>
            </CCol>
          </CRow>
          <!-- Form Routes -->
          <CRow v-if="modules.routes == 1 && (form.form_status_tag === 'draft' || (form.form_status_tag === 'published' && form.routes.length > 0))" class="form_routes m-0">
            <CCol cols="12" lg="12" class="pb-0">
              <label><b>{{$t('forms.Routes_after_submission')}}</b></label>
              <multiselect class="data_table open_absolute"
                           v-model="form.routes"
                           :options="routes" 
                           :multiple="true"
                           :hide-selected="true"
                           :close-on-select="true"
                           :selectLabel="$t('forms.Select_form_route')"
                           :deselectLabel="$t('forms.Remove_form_route')"
                           track-by="route_id_external" 
                           label="route_name"
                           :disabled="form.form_status_tag === 'published'">
                <span slot="noResult">{{$t('forms.No_form_routes_found')}}</span>
              </multiselect>
            </CCol>
          </CRow>
          
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="text-right">
              <div v-if="!formIdExternal">
                <CButton color="primary" @click="insertForm('draft');"><i class="fas fa-file-pen mr-2"/>{{ $t('common.Save_as_draft') }}</CButton>   
                <CButton color="primary" @click="insertForm('published');"><i class="fas fa-check mr-1"/>{{$t('common.Publish')}}</CButton>
                <CButton color="secondary" @click="resetFormData(); showFormOverview();" class="m-0"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
              </div>
              <div v-else>
                <CButton v-if="form.form_status_tag !== 'published'" color="primary" @click="updateForm('draft');"><i class="fas fa-file-pen mr-2"/>{{ $t('common.Save_as_draft') }}</CButton>   
                <CButton color="primary" @click="updateForm('published');"><i class="fas fa-check mr-1"/>{{form.form_status_tag !== 'published' ? $t('common.Publish') : $t('Save')}}</CButton>
                <CButton color="secondary" @click="showFormOverview();" class="m-0"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
              </div>
            </CCol>
          </CRow>          
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import noPermission from '@/components/common/noPermission.vue';

import FormBuilder from "@/components/common/formBuilder.vue";

export default {
  name: 'Form',
  components: {
    Multiselect,
    noPermission,  
    FormBuilder
  },
  watch: { 
    '$route.params.id': {
      handler() {
        // Get the formIdExternal from the route params
        this.formIdExternal = this.$route.params.id;     
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      modules: {
        routes: 0
      },
      platformPermissions: [],
      platformPermissionsLoaded: false,
      formIdExternal: null,
      form: {
        form_name: null,
        form_status_tag: 'draft',
        category: null,  
        available_from: null,
        available_till: null,
        routes: []
      },
      formData: null,
      formAvailableFrom: null,
      formAvailableTill: null,
      formCategories: [],
      routes: [],
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      },
      maxCount: {
        form_description: 250
      },
      remainingCount: {
        form_description: 250
      },      
    }
  },
  methods: {
    getFormDetails() {
      if(this.formIdExternal) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/service/form/' + this.formIdExternal)
        .then(res => {
          this.form = res.data.data;
          // Set the formAvailableFrom and formAvailableTill if the form has availablity set
          if(this.form.available_from) this.formAvailableFrom = new Date(this.form.available_from);
          if(this.form.available_till) this.formAvailableTill = new Date(this.form.available_till);
          // Set the formData
          this.formData = this.form.json;
          // Trigger the countdown method
          this.countdown();
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        this.resetFormData();
      }
    },
    insertForm(statusTag) {
      let params = {};
      params.formData = this.form;
      params.formData.form_status_tag = statusTag;

      let formConfigData = this.$refs.formBuilder.getFormData();
  	  params.formData.json = formConfigData;
      params.formData.fields = this.getFormFields(formConfigData);      
               
      if(this.formAvailableFrom) params.formData.available_from = this.$luxon(this.formAvailableFrom.toISOString(), "yyyy-MM-dd HH:mm:ss");
      if(this.formAvailableTill) params.formData.available_till = this.$luxon(this.formAvailableTill.toISOString(), "yyyy-MM-dd HH:mm:ss");

      let form_name = params.formData.form_name;
      let json = params.formData.json;

      if(form_name && json) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/service/form', params)
        .then(res => {
          this.$buefy.toast.open({ message: statusTag === 'draft' ? this.$t('forms.Form_saved_as_draft') : this.$t('forms.Form_published'), type: 'is-success', duration: 2000 });
          // Return to the forms overview
          this.showFormOverview();
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    updateForm(statusTag) {
      let params = {};
      params.formData = this.form;      
      params.formData.form_status_tag = statusTag;

      let formConfigData = this.$refs.formBuilder.getFormData();
  	  params.formData.json = formConfigData;
      params.formData.fields = this.getFormFields(formConfigData);

      if(this.formAvailableFrom) params.formData.available_from = this.$luxon(this.formAvailableFrom.toISOString(), "yyyy-MM-dd HH:mm:ss");
      if(this.formAvailableTill) params.formData.available_till = this.$luxon(this.formAvailableTill.toISOString(), "yyyy-MM-dd HH:mm:ss");

      let form_name = params.formData.form_name;
      let json = params.formData.json;

      if(form_name && json) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/service/form/' + this.formIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: statusTag === 'draft' ? this.$t('forms.Form_saved_as_draft') : this.$t('forms.Form_published'), type: 'is-success', duration: 2000 });
          // Return to the forms overview
          this.showFormOverview();
        })
        .catch(err => {
          console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    getFormFields(formConfigData) {
      // Convert formData JSON string to JSON object
      let formConfig = JSON.parse(formConfigData);
      // Create a new formFields array
      let formFields = [];
      // Loop through all stages of the formConfig
      Object.keys(formConfig.stages).forEach(stageId => {
        // Extract the rows from the stage
        let stageRows = formConfig.stages[stageId].children;
        // Loop through the rows of the stage
        stageRows.forEach(function(stageRowId) {
          // Extract the columns from the row
          let stageRowColumns = formConfig.rows[stageRowId].children;
          // Loop through the colums of the row
          stageRowColumns.forEach(function(stageRowColumnId) {
            // Extract the fields from the column
            let stageRowColumnFields = formConfig.columns[stageRowColumnId].children;
            // Loop through the fields of the column
            stageRowColumnFields.forEach(function(stageRowColumnFieldId) {
              // Add the fields of the row to the formFields array 
              formFields.push(formConfig.fields[stageRowColumnFieldId]);                
            });
          });
        });
      });
      // Return the formFields array
      return formFields;
    },
    getFormCategories() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/forms/categories/multiselect')
      .then(res => {
        this.formCategories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getRoutes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/routes')
      .then(res => {
        this.routes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    showFormOverview() {
      // Navigate to Service forms page
      this.$router.push({path: '/service/forms'});
    },
    resetFormData() {
      this.form = {
        form_name: null,
        form_status_tag: 'draft',
        category: null,
        available_from: null,
        available_till: null,
        routes: []
      }

      // Check if the newFormCategory item is available in localStorage
      if(localStorage.getItem('newFormCategory') !== null) {
        // Set the category of the form
        this.form.category = JSON.parse(localStorage.getItem('newFormCategory'));
        // Remove the newFormCategory item from localStorage
        localStorage.removeItem('newFormCategory')        
      }
    },
    countdown() {
      if(this.form.form_description) this.remainingCount.form_description = this.maxCount.form_description - this.form.form_description.length;
    },       
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {
        this.modules = res.data.data;
        if(this.modules.routes == 1) this.getRoutes();
      })
      .catch(err => {
        console.error(err); 
      });
    }      
  },
  mounted: function() {
    this.checkModules();
    this.getPlatformPermissions();
    this.getFormCategories();
    this.getFormDetails();
  }
};
</script>