<template>
  <div id="formeoEditor"></div>
</template>

<script>
import { FormeoEditor, FormeoRenderer  } from 'formeo'

export default {
  name: 'formBuilder',
  props: ['formData'],
  components: {
    FormeoEditor,
    FormeoRenderer
  },
  watch: {
    $props: {
      handler() {
        // Update the formBuilderOptions if the formData is available
        if(this.formData) this.formBuilderOptions.formData = JSON.parse(this.formData);
        // Initiate the formeo editor
        setTimeout(function() {
          this.formBuilder = new FormeoEditor(this.formBuilderOptions);
        }.bind(this), 100);
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      formBuilder: null,
      formBuilderOptions: {
        editorContainer: '#formeoEditor',
        config: {
          stages: {
            all: {
              actionButtons: {
                buttons: ['handle', 'clone', 'remove']
              }
            }
          },
          rows: {
            all: {
              actionButtons: {
                buttons: ['handle', 'clone', 'remove']
              }
            }
          },
          columns: {
            all: {
              actionButtons: {
                buttons: ['handle', 'clone', 'remove']
              }
            }
          },          
          fields: {
            all: {
              actionButtons: {
                buttons: ['handle', 'clone', 'remove']
              },
              panels: {
                disabled: ['attrs', 'conditions']
              }
            },
            select: {
              actionButtons: {
                buttons: ['handle', 'edit', 'clone', 'remove']
              }
            },           
            radio: {
              actionButtons: {
                buttons: ['handle', 'edit', 'clone', 'remove']
              }
            }
          }
        },        
        controls: {
          disable: {
            elements: ['hidden', 'upload', 'checkbox', 'button'],
            groups: ['layout', 'html'],
            formActions: ['saveBtn'],
          }
        }
      }
    }
  },
  methods: {
    getFormData() {
      return this.formBuilder.json;
    }
  }
}

</script>